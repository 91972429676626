<template>
  <div class="detail-category-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Activity' }"
        >活動管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'ActivityCategory' }"
        >類別管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>類別內容</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form>
        <el-form-item label="中文名稱">
          <el-input v-model="category.name_zh" disabled></el-input>
        </el-form-item>
        <el-form-item label="英文名稱">
          <el-input v-model="category.name_en" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button
              type="primary"
              @click="
                $router.push({
                  name: 'EditActivityCategory',
                  params: { id: categoryId },
                })
              "
              >編輯類別</el-button
            >
          </div>
        </el-form-item>
      </el-form>

      <table-title>
        <p>
          次類別列表 <span class="unit">數量：{{ total }}</span>
        </p>

        <el-button
          type="success"
          @click="$router.push({ name: 'CreateActivitySubcategory' })"
          >新增次類別
        </el-button>
      </table-title>

      <el-table :data="subcategories">
        <el-table-column
          label="ID"
          prop="subcategory_id"
          sortable
        ></el-table-column>
        <el-table-column
          label="中文名稱"
          prop="name_zh"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文名稱"
          prop="name_en"
          sortable
        ></el-table-column>
        <el-table-column label="編輯" fixed="right" width="180">
          <template v-slot="{ row }">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="
                $router.push({
                  name: 'EditActivitySubcategory',
                  params: { cid: categoryId, scid: row['subcategory_id'] },
                })
              "
              >編輯</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(row['subcategory_id'])"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import { getActivityCategory } from "@/api/activity-category";
import {
  deleteActivitySubcategory,
  getSubcategoriesPagination,
} from "@/api/activity-subcategory";

export default {
  name: "DetailActivityCategory",
  data() {
    return {
      category: {
        name_zh: "",
        name_en: "",
      },
      total: 0,
      setup: {
        page: 0,
        limit: 25,
      },
      subcategories: [],
    };
  },
  computed: {
    categoryId() {
      return +this.$route.params.id;
    },
  },
  created() {
    this.handleGetCategory();
  },
  methods: {
    async handleGetCategory() {
      try {
        const category = await getActivityCategory(this.categoryId);
        this.category = { ...this.category, ...category };

        await this.handleGetSubcategories();
      } catch (e) {
        await this.handleRedirect();
      }
    },
    async handleGetSubcategories() {
      const { results, total } = await getSubcategoriesPagination(
        this.categoryId,
        this.setup
      );
      this.subcategories = results;
      this.total = total;
    },
    async handleRedirect() {
      await this.$router.push({ name: "ActivityCategory" });
    },
    handleChangePage(page) {
      this.setup.page = page;
      this.handleGetSubcategories();
    },
    handleDelete(subcategory_id) {
      this.$confirm("是否刪除該次類別", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteActivitySubcategory(subcategory_id);
          await this.handleGetSubcategories();

          this.$message({
            type: "success",
            message: "刪除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
